import { useToaster } from "react-hot-toast";
import { Transition, TransitionGroup } from "react-transition-group";

import { CSSRulesFunction, ResponsiveValue, useTheme, ZIndex } from "../theme";

import { toasterPositionStyles } from "./toasterStyles";

export enum ToasterPosition {
  TopRight = "top-right",
  BottomLeft = "bottom-left",
}

interface ToasterConfig {
  /**
   * The location on the viewport from which toast notifications will pop out.
   */
  position: ToasterPosition;

  /**
   * The width of the toaster area on the viewport. This will decide the width
   * of toast notifications.
   */
  width: ResponsiveValue<React.CSSProperties["width"]>;

  /**
   * Sets the time for toast transitions. A value of 0 will disable transitions.
   */
  transitionDuration: number;
}

export interface ToasterProps extends Partial<ToasterConfig> {}

const defaults: ToasterConfig = {
  position: ToasterPosition.TopRight,
  width: ["auto", null, "458px"],
  transitionDuration: 400,
};

const containerStyle: CSSRulesFunction<ToasterConfig> = (theme, props) => {
  return [
    {
      position: "fixed",
      zIndex: ZIndex.Toast,
      "> * + *": { marginTop: theme.spacing(1) },
    },
    theme.responsive(props.width, (width) => ({ width })),

    toasterPositionStyles[props.position].container(theme, props),
  ];
};

const Toaster: React.FC<ToasterProps> = (props) => {
  const { theme } = useTheme();
  const config = { ...defaults, ...props };
  const { toasts, handlers } = useToaster();
  const visibleToasts = toasts.filter((t) => t.visible);
  const { startPause, endPause } = handlers;
  const enableTransitions = !!config.transitionDuration;

  return (
    <div
      css={containerStyle(theme, config)}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      onFocus={startPause}
      onBlur={endPause}
    >
      <TransitionGroup
        component={null}
        appear={enableTransitions}
        enter={enableTransitions}
        exit={enableTransitions}
      >
        {visibleToasts.map((toast) => {
          const body =
            typeof toast.message === "function"
              ? toast.message(toast)
              : toast.message;
          return (
            <Transition key={toast.id} timeout={config.transitionDuration}>
              {(state) => {
                const style = toasterPositionStyles[config.position][state]?.(
                  theme,
                  config
                );

                return <div css={style}>{body}</div>;
              }}
            </Transition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

Toaster.defaultProps = defaults;

export default Toaster;
