import { ClassNames } from "@emotion/react";
import * as React from "react";

import { Icon } from "../icons";
import { useTheme } from "../theme";

import { buttonAccessoryStyle, buttonStyle } from "./styles";
import {
  ButtonVariant,
  ButtonRenderProps,
  ButtonStyleProps,
  omitButtonStyleProps,
} from "./types";

type HTMLAttributes = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof ButtonStyleProps
>;

export interface LinkProps extends HTMLAttributes, ButtonStyleProps {
  children: React.ReactNode;
  href: string;
  render?: (props: ButtonRenderProps & { href: string }) => React.ReactNode;
}

const contentStyle = { gridArea: "content" };

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { theme } = useTheme();
  const { leftIcon, rightIcon, variant } = props;
  const { render, ...rest } = omitButtonStyleProps(props);
  const wrappedChildren = (
    <>
      {leftIcon ? (
        <span css={buttonAccessoryStyle(theme, { position: "start", variant })}>
          <Icon name={leftIcon} />
        </span>
      ) : null}

      <span css={contentStyle}>{props.children}</span>
      {rightIcon ? (
        <span css={buttonAccessoryStyle(theme, { position: "end", variant })}>
          <Icon name={rightIcon} />
        </span>
      ) : null}
    </>
  );

  return (
    <ClassNames>
      {({ css, cx }) => {
        const className = cx(css(buttonStyle(theme, props)), props.className);
        const forwardedProps = {
          ...rest,
          className,
          children: wrappedChildren,
        };

        return render ? (
          render(forwardedProps)
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a ref={ref} {...forwardedProps} />
        );
      }}
    </ClassNames>
  );
});

Link.defaultProps = {
  variant: ButtonVariant.PrimaryOnLight,
};

export default Link;
