import * as React from "react";

import { useTheme } from "../theme";
import {
  typographyStyle,
  TypographyStyleProps,
  omitTypographyStyleProps,
} from "../typography/appearance";

import { listStyle, AppearanceProps, omitAppearanceProps } from "./appearance";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLUListElement>,
  keyof TypographyStyleProps
>;

export interface ULProps
  extends HTMLAttributes,
    AppearanceProps,
    TypographyStyleProps {}

const UL = React.forwardRef<HTMLUListElement, ULProps>(function UL(props, ref) {
  const { theme } = useTheme();
  const filtered = omitTypographyStyleProps(omitAppearanceProps(props));
  return (
    <ul
      css={[typographyStyle(theme, props), listStyle(theme, props)]}
      {...filtered}
      ref={ref}
    />
  );
});

UL.defaultProps = {
  marker: "disc",
  gutterH: 1.5,
};

export default UL;
