import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type CheckboxProps = Omit<ToggleProps, "type" | "control">;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const { ...rest } = props;
    return (
      <Toggle
        {...rest}
        control={ToggleControl.Check}
        type="checkbox"
        ref={ref}
      />
    );
  }
);

Checkbox.defaultProps = {
  variant: ToggleVariant.Compact,
  colorScheme: ColorScheme.OnLight,
  controlPosition: ToggleControlPosition.Start,
};

export default Checkbox;
