import { Button, ButtonLayout, ButtonVariant, Link } from "../buttons";
import type { ResponsiveValue } from "../theme";

import type { BannerActionProps } from "./types";

const sharedProps: {
  layout: ResponsiveValue<ButtonLayout>;
} = {
  layout: [ButtonLayout.Full, null, ButtonLayout.Inline],
};

const BannerAction: React.FC<BannerActionProps & { variant: ButtonVariant }> = (
  props
) => {
  if (props.control === "link") {
    const { buttonVariant, control, ...rest } = props;
    // Unnecessarily noisy lint rule in this instance since we are forwarding
    // props in this components.
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <Link {...rest} {...sharedProps} />;
  } else {
    const { buttonVariant, control, ...rest } = props;
    return <Button {...rest} {...sharedProps} />;
  }
};

export default BannerAction;
