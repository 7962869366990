import {
  useTheme,
  SpaceScale,
  CSSRulesFunction,
  ResponsiveValue,
} from "../theme";

export interface SpaceProps {
  v?: ResponsiveValue<SpaceScale>;
  h?: ResponsiveValue<SpaceScale>;
  layout?: ResponsiveValue<"block" | "inline">;
}

const inlineStyle = {
  flexBasis: "auto",
  display: "inline-block",
};

const blockStyle = {
  flexBasis: "100%",
  display: "block",
};

const spaceStyle: CSSRulesFunction<SpaceProps> = (theme, props) => [
  theme.responsive(props.layout, (l) => {
    if (l === "block") {
      return blockStyle;
    } else if (l === "inline") {
      return inlineStyle;
    } else {
      return null;
    }
  }),

  // Hide any sibling Space elements to avoid them stacking their margins and
  // creating undesirably large empty spaces. This can happen when using with
  // Interpose and some children may be null.
  //
  // ```jsx
  // const PotentiallyEmptyComponent = () => (
  //   Math.random() > 0.5 ? <span>yay</span> : null
  // )
  // ```
  // ```jsx
  // <Box>
  //   <Interpose node={<Space v={1} />}>
  //     <PotentiallyEmptyComponent />
  //     <PotentiallyEmptyComponent />
  //     <P>Hello</P>
  //   </Interpose>
  // </Box>
  // ```
  { "&+&": { display: "none" } },

  theme.responsive(props.h, (h) => ({
    paddingRight: theme.spacing(h),
  })),
  theme.responsive(props.v, (v) => ({
    paddingBottom: theme.spacing(v),
  })),
];

const Space: React.FC<SpaceProps> = (props) => {
  const { theme } = useTheme();
  const { v, h, layout, ...rest } = props;
  return (
    <span
      aria-hidden
      data-flux-role="spacer"
      css={spaceStyle(theme, props)}
      {...rest}
    />
  );
};

Space.defaultProps = {
  layout: "block",
  v: 0,
  h: 0,
};

export default Space;
