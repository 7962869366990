import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";

import { useAccessToken } from "src/common/authorisation";

export const useMultiAccountFeature = () => {
  const [accessToken] = useAccessToken();

  const { data, isLoading } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );

  const hasMultiAccountFeature: boolean =
    data?.organisations?.features?.["multi_entity_organisation_tree"] ?? false;

  return {
    hasMultiAccountFeature,
    isLoading,
  };
};
