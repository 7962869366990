import type React from "react";

import * as glyphs from "./glyphs";

export enum Glyph {
  Account = "Account",
  Add = "Add",
  AddCircle = "AddCircle",
  AddCircleOutline = "AddCircleOutline",
  ArrowBack = "ArrowBack",
  ArrowBackCircle = "ArrowBackCircle",
  ArrowDown = "ArrowDown",
  ArrowDownCircle = "ArrowDownCircle",
  ArrowDownCircleLight = "ArrowDownCircleLight",
  ArrowDownOutline = "ArrowDownOutline",
  ArrowForward = "ArrowForward",
  ArrowForwardLong = "ArrowForwardLong",
  ArrowUp = "ArrowUp",
  BankAccount = "BankAccount",
  Book = "Book",
  Bulb = "Bulb",
  Calculate = "Calculate",
  Calculator = "Calculator",
  Calendar = "Calendar",
  CaretDown = "CaretDown",
  CaretLeft = "CaretLeft",
  CaretRight = "CaretRight",
  CaretUp = "CaretUp",
  Charity = "Charity",
  Chat = "Chat",
  ChevronDown = "ChevronDown",
  ChevronLeft = "ChevronLeft",
  ChevronRight = "ChevronRight",
  ChevronUp = "ChevronUp",
  Close = "Close",
  CloseCircle = "CloseCircle",
  CloseCircleLight = "CloseCircleLight",
  CloseOutline = "CloseOutline",
  Code = "Code",
  Company = "Company",
  Copy = "Copy",
  CreditCard = "CreditCard",
  CreditCardOutline = "CreditCardOutline",
  CurvedArrowLeftCircle = "CurvedArrowLeftCircle",
  Data = "Data",
  Desktop = "Desktop",
  Dashboard = "Dashboard",
  DashboardChecked = "DashboardChecked",
  Disc = "Disc",
  DiscSmall = "DiscSmall",
  Document = "Document",
  Download = "Download",
  DownloadCircle = "DownloadCircle",
  Edit = "Edit",
  Exchange = "Exchange",
  Exclamation = "Exclamation",
  ExclamationCircle = "ExclamationCircle",
  ExclamationOutline = "ExclamationOutline",
  ExclamationSmall = "ExclamationSmall",
  Expand = "Expand",
  Export = "Export",
  Fast = "Fast",
  Figma = "Figma",
  Filter = "Filter",
  Global = "Global",
  Guide = "Guide",
  FlagAU = "FlagAU",
  FlagAUDisabled = "FlagAUDisabled",
  FlagCA = "FlagCA",
  FlagCADisabled = "FlagCADisabled",
  FlagDK = "FlagDK",
  FlagDKDisabled = "FlagDKDisabled",
  FlagEU = "FlagEU",
  FlagEUDisabled = "FlagEUDisabled",
  FlagNO = "FlagNO",
  FlagNODisabled = "FlagNODisabled",
  FlagNZ = "FlagNZ",
  FlagNZDisabled = "FlagNZDisabled",
  FlagSE = "FlagSE",
  FlagSEDisabled = "FlagSEDisabled",
  FlagUK = "FlagUK",
  FlagUKDisabled = "FlagUKDisabled",
  FlagUS = "FlagUS",
  FlagUSDisabled = "FlagUSDisabled",
  FlagRectAT = "FlagRectAT",
  FlagRectATDisabled = "FlagRectATDisabled",
  FlagRectAU = "FlagRectAU",
  FlagRectAUDisabled = "FlagRectAUDisabled",
  FlagRectBE = "FlagRectBE",
  FlagRectBEDisabled = "FlagRectBEDisabled",
  FlagRectBG = "FlagRectBG",
  FlagRectBGDisabled = "FlagRectBGDisabled",
  FlagRectCA = "FlagRectCA",
  FlagRectCADisabled = "FlagRectCADisabled",
  FlagRectCH = "FlagRectCH",
  FlagRectCHDisabled = "FlagRectCHDisabled",
  FlagRectCY = "FlagRectCY",
  FlagRectCYDisabled = "FlagRectCYDisabled",
  FlagRectCZ = "FlagRectCZ",
  FlagRectCZDisabled = "FlagRectCZDisabled",
  FlagRectDE = "FlagRectDE",
  FlagRectDEDisabled = "FlagRectDEDisabled",
  FlagRectDK = "FlagRectDK",
  FlagRectDKDisabled = "FlagRectDKDisabled",
  FlagRectEE = "FlagRectEE",
  FlagRectEEDisabled = "FlagRectEEDisabled",
  FlagRectES = "FlagRectES",
  FlagRectESDisabled = "FlagRectESDisabled",
  FlagRectEU = "FlagRectEU",
  FlagRectEUDisabled = "FlagRectEUDisabled",
  FlagRectFI = "FlagRectFI",
  FlagRectFIDisabled = "FlagRectFIDisabled",
  FlagRectFR = "FlagRectFR",
  FlagRectFRDisabled = "FlagRectFRDisabled",
  FlagRectGB = "FlagRectGB",
  FlagRectGBDisabled = "FlagRectGBDisabled",
  FlagRectGR = "FlagRectGR",
  FlagRectGRDisabled = "FlagRectGRDisabled",
  FlagRectHR = "FlagRectHR",
  FlagRectHRDisabled = "FlagRectHRDisabled",
  FlagRectHU = "FlagRectHU",
  FlagRectHUDisabled = "FlagRectHUDisabled",
  FlagRectIE = "FlagRectIE",
  FlagRectIEDisabled = "FlagRectIEDisabled",
  FlagRectIS = "FlagRectIS",
  FlagRectISDisabled = "FlagRectISDisabled",
  FlagRectIT = "FlagRectIT",
  FlagRectITDisabled = "FlagRectITDisabled",
  FlagRectLI = "FlagRectLI",
  FlagRectLIDisabled = "FlagRectLIDisabled",
  FlagRectLT = "FlagRectLT",
  FlagRectLTDisabled = "FlagRectLTDisabled",
  FlagRectLU = "FlagRectLU",
  FlagRectLUDisabled = "FlagRectLUDisabled",
  FlagRectLV = "FlagRectLV",
  FlagRectLVDisabled = "FlagRectLVDisabled",
  FlagRectMT = "FlagRectMT",
  FlagRectMTDisabled = "FlagRectMTDisabled",
  FlagRectNL = "FlagRectNL",
  FlagRectNLDisabled = "FlagRectNLDisabled",
  FlagRectNO = "FlagRectNO",
  FlagRectNODisabled = "FlagRectNODisabled",
  FlagRectNZ = "FlagRectNZ",
  FlagRectNZDisabled = "FlagRectNZDisabled",
  FlagRectPL = "FlagRectPL",
  FlagRectPLDisabled = "FlagRectPLDisabled",
  FlagRectPT = "FlagRectPT",
  FlagRectPTDisabled = "FlagRectPTDisabled",
  FlagRectRO = "FlagRectRO",
  FlagRectRODisabled = "FlagRectRODisabled",
  FlagRectSE = "FlagRectSE",
  FlagRectSEDisabled = "FlagRectSEDisabled",
  FlagRectSI = "FlagRectSI",
  FlagRectSIDisabled = "FlagRectSIDisabled",
  FlagRectSK = "FlagRectSK",
  FlagRectSKDisabled = "FlagRectSKDisabled",
  FlagRectSL = "FlagRectSL",
  FlagRectSLDisabled = "FlagRectSLDisabled",
  FlagRectUS = "FlagRectUS",
  FlagRectUSDisabled = "FlagRectUSDisabled",
  FlagRectZA = "FlagRectZA",
  FlagRectZADisabled = "FlagRectZADisabled",
  GitHub = "GitHub",
  Heart = "Heart",
  Home = "Home",
  InfoCircle = "InfoCircle",
  InfoCircleOutline = "InfoCircleOutline",
  InfoOutline = "InfoOutline",
  InfoSmall = "InfoSmall",
  Invoice = "Invoice",
  Link = "Link",
  LinkedinBlue = "LinkedinBlue",
  LinkedinWhite = "LinkedinWhite",
  Mail = "Mail",
  MailPlane = "MailPlane",
  MailSolid = "MailSolid",
  MoreHorizontal = "MoreHorizontal",
  MoreHorizontalCircle = "MoreHorizontalCircle",
  MoreVertical = "MoreVertical",
  MoveLeftCircle = "MoveLeftCircle",
  Menu = "Menu",
  Merge = "Merge",
  Mobile = "Mobile",
  MobileChat = "MobileChat",
  Money = "Money",
  Notification = "Notification",
  Office = "Office",
  One = "One",
  Package = "Package",
  Padlock = "Padlock",
  PadlockCircle = "PadlockCircle",
  Paper = "Paper",
  PaperClip = "PaperClip",
  Pause = "Pause",
  PauseCircle = "PauseCircle",
  Percentage = "Percentage",
  Phone = "Phone",
  Pin = "Pin",
  Play = "Play",
  PlayCircle = "PlayCircle",
  PlayOutline = "PlayOutline",
  PrivacyShield = "PrivacyShield",
  Quote = "Quote",
  React = "React",
  Recurring = "Recurring",
  Refresh = "Refresh",
  Refund = "Refund",
  Search = "Search",
  Settings = "Settings",
  Share = "Share",
  Spinner = "Spinner",
  SpinnerLight = "SpinnerLight",
  Stopwatch = "Stopwatch",
  Subtract = "Subtract",
  SubtractCircle = "SubtractCircle",
  SubtractSmall = "SubtractSmall",
  Support = "Support",
  Swap = "Swap",
  Table = "Table",
  TableComfortable = "TableComfortable",
  TableCompact = "TableCompact",
  Tick = "Tick",
  TickCircle = "TickCircle",
  TickCircleLight = "TickCircleLight",
  TickOutline = "TickOutline",
  TickSmall = "TickSmall",
  TickSquare = "TickSquare",
  Toggle = "Toggle",
  ToggleOff = "ToggleOff",
  Tooltip = "Tooltip",
  Trash = "Trash",
  Twitter = "Twitter",
  User = "User",
  Users = "Users",
  Verified = "Verified",
  View = "View",
  ViewHidden = "ViewHidden",
  Warning = "Warning",
  Youtube = "Youtube",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",

  // Banks
  BankOfIreland = "BankOfIreland",
  BankOfScotland = "BankOfScotland",
  Barclays = "Barclays",
  ClydesdaleBank = "ClydesdaleBank",
  Coutts = "Coutts",
  DanskeBank = "DanskeBank",
  Halifax = "Halifax",
  HSBC = "HSBC",
  LloydsBank = "LloydsBank",
  Monzo = "Monzo",
  Nationwide = "Nationwide",
  Natwest = "Natwest",
  Revolut = "Revolut",
  RoyalBankOfScotland = "RoyalBankOfScotland",
  Santander = "Santander",
  StarlingBank = "StarlingBank",
  TSBBank = "TSBBank",
  YorkshireBank = "YorkshireBank",
}

const registry: Record<
  Glyph,
  React.ComponentType<{
    className?: string | undefined;
    children?: undefined;
  }>
> = glyphs;

export default registry;
