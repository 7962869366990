import { ButtonGutter, ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { ButtonSize } from "../theme";

import { BannerVariant } from "./types";

const CloseAction: React.FC<{
  variant: BannerVariant;
  isLayoutHorizontal: boolean;
  label: React.ReactNode;
  onClose(): void;
}> = (props) => {
  const { variant, label, onClose: onClick } = props;
  const btnProps = {
    label,
    onClick,
    icon: Glyph.Close,
    size: { base: ButtonSize.Sm, gutters: ButtonGutter.Md },
  };

  const shift = `calc(-1 * var(--btn-gutter-${btnProps.size.base}-${btnProps.size.gutters}))`;
  const margin = ` ${!props.isLayoutHorizontal ? shift : 0} ${shift} 0 0`;

  switch (variant) {
    case BannerVariant.Dark:
      return (
        <IconButton
          {...btnProps}
          css={{ margin }}
          variant={ButtonVariant.TextOnDark}
        />
      );
    default:
      return (
        <IconButton
          {...btnProps}
          css={{ margin }}
          variant={ButtonVariant.TextOnLight}
        />
      );
  }
};

export default CloseAction;
