import type * as React from "react";

import { CSSRulesFunction, useTheme, FontWeight } from "../theme";
import {
  TypographyStyleProps,
  omitTypographyStyleProps,
  typographyStyle,
} from "../typography/appearance";

import { gutterStyle, GutterProps } from "./appearance";

type HTMLAttributes = Omit<
  React.ThHTMLAttributes<HTMLTableCellElement>,
  "color"
>;

export interface TableHeadCellProps
  extends HTMLAttributes,
    GutterProps,
    Omit<
      TypographyStyleProps,
      "spaceAbove" | "spaceBelow" | "layout" | "emptyVisibility"
    > {
  children?: React.ReactNode;
}

export const tableHeadCellStyle: CSSRulesFunction<TableHeadCellProps> = (
  theme,
  props
) => [
  {
    textAlign: "start",
    verticalAlign: "top",
    fontWeight: FontWeight.SemiBold,
  },
  typographyStyle(theme, { ...props, emptyVisibility: "visible" }),
  gutterStyle(theme, props),
];

const TableHeadCell: React.FC<TableHeadCellProps> = (props) => {
  const { theme } = useTheme();
  const { gutterV, gutterH, ...filtered } = omitTypographyStyleProps(props);
  return <th css={tableHeadCellStyle(theme, props)} {...filtered} />;
};

TableHeadCell.defaultProps = {
  gutterH: 1.5,
  gutterV: 1,
};

export default TableHeadCell;
