import { ButtonSize, ColorScheme, CSSRulesFunction, FocusRing } from "../theme";
import { focusRingStyle } from "../theme/focusRings";

import { roundedButtonColorStyle } from "./rounded";
import { ButtonGutter, IconButtonStyleProps } from "./types";
import { buttonVariantTokenStyle } from "./variants";

const iconButtonSizeTokens: CSSRulesFunction = (theme) => {
  return {
    "--btn-gutter-sm-sm": theme.spacing(0.25),
    "--btn-gutter-sm-md": theme.spacing(0.75),

    "--btn-gutter-md-sm": theme.spacing(0.25),
    "--btn-gutter-md-md": theme.spacing(1),

    "--btn-gutter-lg-sm": theme.spacing(0.5),
    "--btn-gutter-lg-md": theme.spacing(1),

    "--btn-font-size-sm": "12px",
    "--btn-font-size-md": "14px",
    "--btn-font-size-lg": "22px",
  };
};

const iconButtonGeometryStyle: CSSRulesFunction<IconButtonStyleProps> = (
  theme,
  props
) => {
  const { size = ButtonSize.Md } = props;

  return [
    iconButtonSizeTokens(theme),
    theme.responsive(size, (v) => {
      let s: ButtonSize;
      let g: ButtonGutter;
      if (typeof v === "object" && "base" in v) {
        s = v.base;
        g = v.gutters;
      } else {
        s = v;
        g = ButtonGutter.Md;
      }

      return {
        padding: `var(--btn-gutter-${s}-${g})`,
        fontSize: `var(--btn-font-size-${s})`,
        lineHeight: 1,
        borderRadius: `calc(var(--btn-font-size-${s}) + var(--btn-gutter-${s}-${g}))`,
        width: `calc(var(--btn-font-size-${s}) + 2*var(--btn-gutter-${s}-${g}))`,
        height: `calc(var(--btn-font-size-${s}) + 2*var(--btn-gutter-${s}-${g}))`,
      };
    }),
  ];
};

const iconButtonColorStyle = roundedButtonColorStyle;

export const iconButtonStyle: CSSRulesFunction<IconButtonStyleProps> = (
  theme,
  props
) => {
  const { focusRing = FocusRing.Heavy } = props;
  let colorScheme: ColorScheme;
  if (props.variant.includes("on-dark")) {
    colorScheme = ColorScheme.OnDark;
  } else if (props.variant.includes("auto")) {
    colorScheme = ColorScheme.Auto;
  } else {
    colorScheme = ColorScheme.OnLight;
  }

  return [
    {
      display: "inline-flex",
      fontFamily: "inherit",
      border: "none",
      appearance: "none",
      textDecoration: "none",
      cursor: "pointer",
      "&:disabled": {
        cursor: "not-allowed",
      },
      transition: "color 100ms, background 100ms, box-shadow 100ms",
    },
    buttonVariantTokenStyle(theme, props),
    focusRingStyle(theme, {
      variant: focusRing,
      focusSelector: "&:focus-visible",
      colorScheme,
    }),
    iconButtonGeometryStyle(theme, props),
    iconButtonColorStyle(theme, props),
  ];
};
