import { ColorScheme, CSSRulesFunction, TypeScale } from "../theme";

import { inlineButtonStyle } from "./inline";
import { roundedButtonStyle } from "./rounded";
import { textButtonStyle } from "./text";
import { ButtonStyleProps, ButtonVariant } from "./types";
import { buttonVariantTokenStyle } from "./variants";

export const buttonGridStyle: CSSRulesFunction<ButtonStyleProps> = (
  _,
  props
) => {
  const { leftIcon, rightIcon } = props;

  let areas = "content";
  if (leftIcon) {
    areas = `start-icon ${areas}`;
  }
  if (rightIcon) {
    areas = `${areas} end-icon`;
  }
  areas = `"${areas}"`;

  return {
    justifyContent: "center",
    gridTemplateAreas: areas,
  };
};

export const buttonAccessoryStyle: CSSRulesFunction<{
  position: "start" | "end";
  variant: ButtonStyleProps["variant"];
}> = (theme, { position, variant }) => {
  let isInline = false;
  switch (variant) {
    case ButtonVariant.Inline:
    case ButtonVariant.InlineUnderlined:
      isInline = true;
      break;
  }

  return [
    {
      display: "inline-flex",
      gridArea: position === "start" ? "start-icon" : "end-icon",
      fontSize: theme.tokens.fontSizes[TypeScale.Size_01].fontSize,
      lineHeight: 1,
      paddingRight:
        isInline && position === "start" ? theme.spacing(0.5) : undefined,
      paddingLeft:
        isInline && position === "end" ? theme.spacing(0.5) : undefined,
    },
  ];
};

export const buttonStyle: CSSRulesFunction<ButtonStyleProps> = (
  theme,
  props
) => {
  let colorScheme: ColorScheme;
  if (props.variant.includes("on-dark")) {
    colorScheme = ColorScheme.OnDark;
  } else if (props.variant.includes("auto")) {
    colorScheme = ColorScheme.Auto;
  } else {
    colorScheme = ColorScheme.OnLight;
  }

  const allProps = {
    colorScheme,
    ...props,
  };

  switch (props.variant) {
    case ButtonVariant.PrimaryOnLight:
    case ButtonVariant.PrimaryOnDark:
    case ButtonVariant.PrimaryWhiteOnDark:
    case ButtonVariant.PrimaryAuto:
    case ButtonVariant.SecondaryOnLight:
    case ButtonVariant.SecondaryOnDark:
    case ButtonVariant.SecondaryAuto:
    case ButtonVariant.AlertOnLight:
    case ButtonVariant.AlertOnDark:
    case ButtonVariant.AlertAuto:
      return [
        buttonVariantTokenStyle(theme, props),
        buttonGridStyle(theme, props),
        roundedButtonStyle(theme, allProps),
      ];
    case ButtonVariant.TextOnLight:
    case ButtonVariant.TextOnDark:
    case ButtonVariant.TextAuto:
      return [
        buttonVariantTokenStyle(theme, props),
        buttonGridStyle(theme, props),
        roundedButtonStyle(theme, allProps),
        textButtonStyle,
      ];
    case ButtonVariant.Inline:
    case ButtonVariant.InlineUnderlined:
      return [inlineButtonStyle(theme, props)];
  }
};

export { iconButtonStyle } from "./iconic";
