import { Glyph, Icon } from "../icons";
import { Interpolation, useTheme } from "../theme";
import { focusRingVar } from "../theme/cssvariables";

export interface AccordionHeaderProps {
  id: string;
  itemId: string;
  disabled: boolean;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  open: boolean;
  toggleItem(itemId: string): void;
  toggleEnabled: boolean;

  children: React.ReactNode;
}

const accordionHeaderStyle: Interpolation = {
  width: "100%",
  display: "grid",
  textAlign: "start",
  columnGap: "var(--accordion-header-column-gap)",
  alignItems: "center",
  cursor: "pointer",

  border: "none",
  background: "none",
  padding:
    "var(--accordion-header-gutter-block) var(--accordion-header-gutter-inline)",
  fontFamily: "inherit",
  fontWeight: "var(--accordion-header-font-weight)",
  fontSize: "var(--accordion-header-font-size)",
  lineHeight: "var(--accordion-header-line-height)",

  color: "var(--accordion-header-color)",

  transition: "background-color 200ms",

  boxShadow: `var(${focusRingVar})`,

  "&:hover .underlineEffect, &:focus-visible:focus .underlineEffect, &:active .underlineEffect":
    {
      color: "var(--accordion-active-header-color)",
    },

  "&:disabled, &[aria-disabled=true]": {
    cursor: "initial",
  },
};

const leftAccessoryStyle: Interpolation = {
  width: "var(--accordion-left-accessory-width)",
  display: "flex",
  justifyContent: "center",
};

const rightAccessoryStyle: Interpolation = {
  display: "flex",
  justifyContent: "flex-end",
};

const accordionHeaderTitleStyle: Interpolation = {
  textDecoration: "underline",
  textDecorationColor: "transparent",
  transition: "text-decoration-color 200ms",

  "button:hover > &, button:focus-visible:focus > &, button:active > &": {
    textDecorationColor: "currentColor",
  },

  "button:disabled > &, button[aria-disabled=true] > &": {
    textDecorationColor: "transparent",
  },
};

const AccordionHeader: React.FC<AccordionHeaderProps> = (props) => {
  const { theme } = useTheme();
  const {
    id,
    toggleItem,
    disabled,
    itemId,
    leftAccessory,
    rightAccessory,
    open,
    toggleEnabled,
  } = props;
  // start with column size of title
  let gridTemplateColumns = "auto";
  if (leftAccessory) {
    gridTemplateColumns = `32px ${gridTemplateColumns}`;
  }
  if (rightAccessory) {
    gridTemplateColumns = `${gridTemplateColumns} auto`;
  }
  // finally make space for the toggle
  if (toggleEnabled) {
    gridTemplateColumns = `${gridTemplateColumns} max-content`;
  }

  return (
    <button
      id={id}
      css={[
        accordionHeaderStyle,
        { gridTemplateColumns, borderRadius: theme.radius(0.25) },
      ]}
      type="button"
      aria-expanded={open}
      aria-disabled={disabled}
      aria-controls={itemId}
      data-accordion-trigger
      onClick={() => toggleItem(itemId)}
    >
      {leftAccessory ? (
        <span css={leftAccessoryStyle}>{leftAccessory}</span>
      ) : null}
      <span css={accordionHeaderTitleStyle}>{props.children}</span>
      {rightAccessory ? (
        <span css={rightAccessoryStyle}>{rightAccessory}</span>
      ) : null}
      {toggleEnabled ? (
        <Icon name={props.open ? Glyph.ChevronUp : Glyph.ChevronDown} />
      ) : null}
    </button>
  );
};

export default AccordionHeader;
