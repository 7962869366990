import * as React from "react";

import { VisuallyHidden } from "../a11y";
import { Box } from "../layout";
import { ColorScheme, useTheme } from "../theme";

import { progressBarStyle } from "./progressBar.styles";
import SegmentedProgressBar from "./SegmentedProgressBar";
import SolidProgressBar from "./SolidProgressBar";
import {
  ProgressBarVariant,
  ProgressBarStyleProps,
  ProgressBarColor,
} from "./types";

type ProgressBarHTMLProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof ProgressBarStyleProps
>;

export interface ProgressBarProps
  extends ProgressBarHTMLProps,
    ProgressBarStyleProps {
  /**
   * The label that is associated with the progress bar
   */
  label: React.ReactNode;

  /**
   * Sets the visibility of the label. Labels can be hidden visually but made
   * available for assistive technology.
   */
  labelVisibility?: "visible" | "hidden";
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { theme } = useTheme();
  const {
    label,
    value,
    max,
    variant,
    labelVisibility,
    borderRadius,
    colorScheme = ColorScheme.OnLight,
    color,
    ...rest
  } = props;
  const styleProps = { value, max, variant, borderRadius, colorScheme };

  const node: React.ReactNode =
    variant === ProgressBarVariant.Segmented ? (
      <SegmentedProgressBar {...styleProps} />
    ) : (
      <SolidProgressBar {...styleProps} />
    );

  return (
    <div
      css={progressBarStyle(theme, props)}
      role="progressbar"
      aria-valuenow={props.value}
      aria-valuemax={props.max}
      {...rest}
    >
      {label && React.Children.count(label) > 0 ? (
        labelVisibility === "hidden" ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          <Box spaceBelow={0.5}>{label}</Box>
        )
      ) : null}
      {node}
    </div>
  );
};

ProgressBar.defaultProps = {
  labelVisibility: "visible",
  color: ProgressBarColor.Primary,
};

export default ProgressBar;
