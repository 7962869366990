import * as React from "react";

import { Box, XYGrid } from "../layout";
import {
  AlignItems,
  JustifyContent,
  JustifyItems,
  TypePreset,
  TypeScale,
  useTheme,
} from "../theme";
import { H4 } from "../typography";

import {
  BannerActionProps,
  BannerCloseActionProps,
  BannerLayout,
  BannerLeftAccessoryProps,
  BannerLeftAccessoryType,
  BannerVariant,
} from "./types";
import { bannerActionsLayout, bannerStyle, BannerThemingProps } from "./styles";
import BannerStatusIcon from "./BannerStatusIcon";
import PrimaryAction from "./PrimaryAction";
import SecondaryAction from "./SecondaryAction";
import CloseAction from "./CloseAction";
import BannerIcon from "./BannerIcon";
import BannerTag from "./BannerTag";

interface BannerStyleProps extends BannerThemingProps {
  /**
   * The title that will be shown on the notification.
   */
  title?: React.ReactNode;

  /** Configures a close action that will be displayed on the banner. */
  closeAction?: BannerCloseActionProps;

  /**
   * Configures an optional, primary action to show with the toast message.
   * This action can be either a link or a button.
   */
  primaryAction?: BannerActionProps;

  /**
   * Configures an optional, secondary action to show with the toast message.
   * This action can be either a link or a button.
   */
  secondaryAction?: BannerActionProps;

  /** Configures the banner layout. */
  layout?: BannerLayout;

  /** Configures the left accessory of the banner. */
  leftAccessory?: BannerLeftAccessoryProps;
}

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof BannerStyleProps
>;

const getHorizontalGridTemplateAreas = (
  closeArea: string,
  showActions: boolean
) => {
  return [
    `
      'icon ${closeArea || "icon"}'
      'body body'
      ${showActions ? "'actions actions'" : ""}
      `,
    null,
    `
      'icon body ${showActions ? "actions" : "body"} ${closeArea}'
      'icon body ${showActions ? "actions" : "body"} ${closeArea}'
      'icon body ${showActions ? "actions" : "body"} ${closeArea}'
    `,
  ];
};

const getGridTemplateAreas = (
  closeArea: string,
  showActions: boolean,
  isHorizontal?: boolean,
  leftAccessory?: BannerLeftAccessoryProps
) => {
  if (isHorizontal) {
    return getHorizontalGridTemplateAreas(closeArea, showActions);
  }

  if (leftAccessory && leftAccessory.type === BannerLeftAccessoryType.Tag) {
    return [
      `
      'icon ${closeArea || "icon"}'
      'body body'
      ${showActions ? "'actions actions'" : ""}
      `,
      null,
      `
      'icon icon close'
      'body body ${closeArea}'
      ${showActions ? "'actions actions " + closeArea + "'" : ""}
    `,
    ];
  }

  return [
    `
    'icon ${closeArea || "icon"}'
    'body body'
    ${showActions ? "'actions actions'" : ""}
    `,
    null,
    `
    'icon body ${closeArea}'
    'icon body ${closeArea}'
    'icon ${showActions ? "actions" : "body"} ${closeArea}'
  `,
  ];
};

const getBannerLeftAccessory = (
  bannerVariant: BannerVariant,
  leftAccessory?: BannerLeftAccessoryProps
) => {
  if (!leftAccessory) {
    return null;
  }

  switch (leftAccessory.type) {
    case BannerLeftAccessoryType.Icon:
      return <BannerIcon variant={bannerVariant} icon={leftAccessory.name} />;

    case BannerLeftAccessoryType.Status:
      return (
        <BannerStatusIcon
          variant={bannerVariant}
          value={leftAccessory.status}
        />
      );

    case BannerLeftAccessoryType.Tag:
      return <BannerTag variant={bannerVariant} text={leftAccessory.text} />;
  }
};

export interface BannerProps extends HTMLAttributes, BannerStyleProps {}

const omitBannerProps = <Props extends BannerStyleProps>(
  props: Props
): Omit<Props, keyof BannerStyleProps> => {
  const {
    closeAction,
    primaryAction,
    secondaryAction,
    leftAccessory,
    title,
    variant,
    backgroundColor,
    elevation,
    layout,
    ...rest
  } = props;

  return rest;
};

const Banner = React.forwardRef<HTMLDivElement, BannerProps>((props, ref) => {
  const { theme } = useTheme();

  const {
    id,
    title,
    children,
    primaryAction,
    secondaryAction,
    closeAction,
    variant,
    layout,
    leftAccessory,
  } = props;
  const showActions = primaryAction || secondaryAction;

  const role = closeAction ? "alertdialog" : "alert";
  const headingId = id ? `${id}_heading` : "";

  const closeArea = closeAction ? "close" : "";
  const closeWidth = closeAction ? "auto" : "";

  const isHorizontal = layout === BannerLayout.Horizontal;
  const hasLeftAccessory = !!leftAccessory;
  const templateColumns = [
    `auto auto`,
    null,
    `max-content 1fr ${isHorizontal ? "max-content" : ""} ${closeWidth}`,
  ];

  return (
    <div
      css={bannerStyle(theme, props)}
      aria-live="off"
      aria-labelledby={headingId}
      {...omitBannerProps(props)}
      ref={ref}
      role={role}
      id={id}
    >
      <XYGrid
        templateAreas={getGridTemplateAreas(
          closeArea,
          !!showActions,
          isHorizontal,
          leftAccessory
        )}
        templateColumns={templateColumns}
        columnGap={hasLeftAccessory ? 1 : 0}
        rowGap={1}
      >
        <Box
          gridArea="body"
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Center}
        >
          {title && (
            <H4
              id={headingId}
              preset={TypePreset.Heading_02}
              size={TypeScale.Size_03}
              spaceBelow={0.25}
            >
              {title}
            </H4>
          )}
          <Box>{children}</Box>
        </Box>
        {showActions ? (
          <Box
            gridArea="actions"
            spaceAbove={isHorizontal ? 0 : 0.25}
            spaceBefore={hasLeftAccessory ? 0 : 1}
            spaceAfter={hasLeftAccessory ? 0 : 1}
            css={bannerActionsLayout(theme)}
          >
            <XYGrid
              rowGap={1}
              columnGap={1}
              justifyItems={JustifyItems.Start}
              alignItems={AlignItems.Center}
              templateColumns={["auto", null, "max-content  max-content"]}
            >
              <PrimaryAction value={primaryAction} variant={variant} />
              <SecondaryAction
                value={secondaryAction}
                variant={variant}
                prioritized={!primaryAction}
              />
            </XYGrid>
          </Box>
        ) : null}

        {closeAction ? (
          <Box
            gridArea="close"
            layout="flex"
            justifyContent={JustifyContent.FlexEnd}
            alignItems={isHorizontal ? AlignItems.Center : AlignItems.Start}
          >
            <CloseAction
              variant={variant}
              isLayoutHorizontal={isHorizontal}
              label={closeAction.label}
              onClose={closeAction.onClose}
            />
          </Box>
        ) : null}

        <Box
          layout="flex"
          alignItems={[
            isHorizontal && !props.title
              ? AlignItems.Center
              : AlignItems.FlexEnd,
            null,
            isHorizontal && !props.title
              ? AlignItems.Center
              : AlignItems.FlexStart,
          ]}
          gridArea="icon"
          aria-hidden
        >
          {getBannerLeftAccessory(variant, leftAccessory)}
        </Box>
      </XYGrid>
    </div>
  );
});

Banner.defaultProps = {
  variant: BannerVariant.Light,
};

export default Banner;
