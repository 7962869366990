import type * as React from "react";

import { VisuallyHidden } from "../a11y";
import { Glyph, Icon } from "../icons";
import {
  ColorPreset,
  ColorProp,
  CSSRulesFunction,
  Interpolation,
  ResponsiveValue,
  TypeScale,
  useTheme,
} from "../theme";
import { Text } from "../typography";

export enum AvatarSize {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

interface IconAvatarProps {
  /**
   * The icon to display as the subject of the avatar
   */
  icon: Glyph;
  /**
   * An optional descriptive label to accompany the avatar.
   */
  assistiveLabel?: React.ReactNode;

  src?: never;
  alt?: never;
  text?: never;
}

interface ImageAvatarProps {
  /**
   * The URL of an image that will be the subject of the avatar.
   */
  src: string;
  /**
   * Descriptive text to accompany an image.
   */
  alt: string;

  text?: never;
  icon?: never;
  assistiveLabel?: never;
}

interface TextAvatarProps {
  /**
   * The text from an initial will be used as the subject of the avatar.
   */
  text: string;

  src?: never;
  alt?: never;
  icon?: never;
  assistiveLabel?: never;
}

interface AvatarStyleProps {
  /**
   * The size of the avatar
   */
  size: ResponsiveValue<AvatarSize>;
  /**
   * The text and icon color of the avatar
   */
  color?: ColorProp;
  /**
   * The background color of the avatar
   */
  bg?: ColorProp;
}

type AvatarCompositeProps = (
  | IconAvatarProps
  | ImageAvatarProps
  | TextAvatarProps
) &
  AvatarStyleProps;

export type AvatarProps = AvatarCompositeProps &
  Omit<React.HTMLAttributes<HTMLDivElement>, keyof AvatarCompositeProps>;

const firstUnicodeChar = (input: string) => {
  let out = "";
  // iterate over unicode codepoints
  for (const ch of input) {
    // skip whitespace characters
    if (ch.trim() !== "") {
      out = ch;
      break;
    }
  }
  return out;
};

const sizes: Record<AvatarSize, string> = {
  [AvatarSize.Xs]: "24px",
  [AvatarSize.Sm]: "32px",
  [AvatarSize.Md]: "48px",
  [AvatarSize.Lg]: "64px",
};

const fontSizes: Record<AvatarSize, TypeScale> = {
  [AvatarSize.Xs]: TypeScale.Size_01,
  [AvatarSize.Sm]: TypeScale.Size_02,
  [AvatarSize.Md]: TypeScale.Size_05,
  [AvatarSize.Lg]: TypeScale.Size_07,
};

const iconSizes: Record<AvatarSize, TypeScale> = {
  [AvatarSize.Xs]: TypeScale.Size_01,
  [AvatarSize.Sm]: TypeScale.Size_01,
  [AvatarSize.Md]: TypeScale.Size_05,
  [AvatarSize.Lg]: TypeScale.Size_06,
};

const sizeVar = "--avatar-size";

const avatarStyle: CSSRulesFunction<AvatarCompositeProps> = (theme, props) => {
  const { color, bg } = props;
  return [
    {
      overflow: "hidden",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
      backgroundColor: bg ? theme.color(bg) : undefined,
      color: color ? theme.color(color) : undefined,
    },
    theme.responsive(props.size, (s) => {
      const fs = props.icon ? iconSizes[s] : fontSizes[s];
      const sv = `var(${sizeVar})`;

      return {
        [sizeVar]: sizes[s],
        width: sv,
        height: sv,
        borderRadius: sv,
        ...theme.tokens.fontSizes[fs],
      };
    }),
  ];
};

const avatarImageStyle: Interpolation = {
  display: "block",
  width: `var(${sizeVar})`,
  height: `var(${sizeVar})`,
  objectFit: "cover",
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const { theme } = useTheme();
  const { size, alt, bg, color, icon, src, text, assistiveLabel, ...rest } =
    props;

  let content: React.ReactNode = null;
  if (props.src) {
    content = (
      <img
        css={avatarImageStyle}
        src={props.src}
        alt={props.alt}
        loading="lazy"
      />
    );
  } else if (props.text) {
    content = (
      <Text aria-label={props.text} textTransform="uppercase">
        {firstUnicodeChar(props.text)}
      </Text>
    );
  } else if (props.icon) {
    content = (
      <>
        <Icon name={props.icon} />
        {props.assistiveLabel ? (
          <VisuallyHidden>{props.assistiveLabel}</VisuallyHidden>
        ) : null}
      </>
    );
  }

  return (
    <div css={avatarStyle(theme, props)} {...rest}>
      {content}
    </div>
  );
};

Avatar.defaultProps = {
  size: AvatarSize.Md,
  bg: ColorPreset.BackgroundLight_03,
  color: ColorPreset.TextOnLight_01,
};

export default Avatar;
