import {
  CSSRulesFunction,
  useTheme,
  AlignItems,
  ResponsiveValue,
} from "../theme";

export interface ReadingOptimizedProps {
  /**
   * Sets the reading width of text. If the computed width of text is longer
   * than this setting then it will wrap.
   */
  textWidth?: ResponsiveValue<string>;

  /**
   * Sets the flex alignment of child elements with respect to this component's
   * flex container
   */
  alignText?: ResponsiveValue<AlignItems>;

  children: React.ReactNode;
}

const textSelector = [
  "> p",
  "> h1",
  "> h2",
  "> h3",
  "> h4",
  "> h5",
  "> h6",
  "> blockquote",
  "> cite",
  "> img",
  "> ul",
  "> ol",
  "> table",
  "> .gatsby-highlight",
  "> [data-reading-optimized]",
  "> pre",
  "> dl",
].join(", ");

export const readingOptimizedStyle: CSSRulesFunction<ReadingOptimizedProps> = (
  theme,
  props
) => {
  return [
    {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      [textSelector]: { maxWidth: "100%" },
    },
    theme.responsive(props.textWidth, (width) => ({
      [textSelector]: { width },
    })),
    theme.responsive(props.alignText, (alignSelf) => ({
      [textSelector]: { alignSelf },
    })),
  ];
};

/**
 * The ReadingOptimized component is used to style blocks of text elements in a
 * way that creates a good reading experience. This includes setting a definite
 * width that corresponds to an optimal characters-per-line value.
 */
const ReadingOptimized: React.FC<ReadingOptimizedProps> = (props) => {
  const { theme } = useTheme();
  const { textWidth, alignText, ...rest } = props;
  return (
    <div css={readingOptimizedStyle(theme, props)} {...rest}>
      {props.children}
    </div>
  );
};

ReadingOptimized.defaultProps = {
  textWidth: ["100%", null, "41rem"],
  alignText: [AlignItems.FlexStart, null, AlignItems.Center],
};

export default ReadingOptimized;
