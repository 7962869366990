import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type SwitchProps = Omit<ToggleProps, "type" | "control">;

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  props,
  ref
) {
  const { ...rest } = props;
  return (
    <Toggle
      {...rest}
      control={ToggleControl.Switch}
      type="checkbox"
      ref={ref}
    />
  );
});

Switch.defaultProps = {
  variant: ToggleVariant.Compact,
  colorScheme: ColorScheme.OnLight,
  controlPosition: ToggleControlPosition.Start,
};

export default Switch;
