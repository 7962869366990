/* eslint-disable deprecation/deprecation */
import {
  ResponsiveValue,
  CSSRulesFunction,
  useTheme,
  SpaceScale,
  CSSValue,
  ColorProp,
  ColorPreset,
} from "../theme";

export interface SeparatorProps {
  direction?: "inline-vertical" | "block-horizontal";
  color?: ResponsiveValue<ColorProp>;
  spacing?: ResponsiveValue<CSSValue<SpaceScale>>;
  thickness?: ResponsiveValue<number>;
  opacity?: ResponsiveValue<number>;
  length?: ResponsiveValue<string>;
  align?: "center" | "left" | "right" | "top" | "bottom";
}

const directionStyle: CSSRulesFunction<SeparatorProps> = (theme, props) => {
  if (props.direction === "inline-vertical") {
    return [
      {
        display: "inline-block",
        width: "1px",
        height: "auto",
        minHeight: "1.4em",
        flex: "0 0 auto",
      },
      theme.responsive(props.thickness, (width) => ({
        height: "auto",
        width,
      })),
      theme.responsive(props.length, (length) => ({
        height: length,
      })),
      theme.responsive(props.align, (alignment) => {
        switch (alignment) {
          case "center":
            return { marginTop: "auto", marginBottom: "auto" };
          case "top":
            return { marginBottom: "auto" };
          case "bottom":
            return { marginTop: "auto" };
          default:
            return null;
        }
      }),
    ];
  } else {
    return [
      {
        display: "block",
        height: "1px",
      },
      theme.responsive(props.thickness, (height) => ({
        width: "auto",
        height,
      })),
      theme.responsive(props.length, (length) => ({
        width: length,
      })),
      theme.responsive(props.align, (alignment) => {
        switch (alignment) {
          case "center":
            return { marginLeft: "auto", marginRight: "auto" };
          case "left":
            return { marginRight: "auto" };
          case "right":
            return { marginLeft: "auto" };
          default:
            return null;
        }
      }),
    ];
  }
};

const separatorStyle: CSSRulesFunction<SeparatorProps> = (theme, props) => {
  return [
    { lineHeight: 1, verticalAlign: "middle" },
    theme.responsive(props.spacing, (space) => ({
      margin: theme.spacing(space),
    })),
    directionStyle(theme, props),
    theme.responsive(props.color, (color) => ({
      backgroundColor: theme.color(color),
    })),
    theme.responsive(props.opacity, (opacity) => ({
      opacity: opacity,
    })),
  ];
};

const Separator: React.FC<SeparatorProps> = (props) => {
  const { theme } = useTheme();
  const {
    direction,
    thickness,
    color,
    spacing,
    opacity,
    length,
    align,
    ...rest
  } = props;
  return (
    <span
      aria-hidden
      data-flux-role="spacer"
      css={separatorStyle(theme, {
        direction,
        thickness,
        color,
        spacing,
        opacity,
        length,
        align,
      })}
      {...rest}
    />
  );
};

Separator.defaultProps = {
  color: ColorPreset.BorderOnLight_04,
};

export default Separator;
