import * as React from "react";

import { useTheme } from "../theme";
import {
  typographyStyle,
  TypographyStyleProps,
  omitTypographyStyleProps,
} from "../typography/appearance";

import { listStyle, AppearanceProps, omitAppearanceProps } from "./appearance";

type HTMLAttributes = Omit<
  React.OlHTMLAttributes<HTMLOListElement>,
  keyof TypographyStyleProps
>;

export interface OLProps
  extends HTMLAttributes,
    AppearanceProps,
    TypographyStyleProps {}

const OL = React.forwardRef<HTMLOListElement, OLProps>(function OL(props, ref) {
  const { theme } = useTheme();
  const filtered = omitTypographyStyleProps(omitAppearanceProps(props));
  return (
    <ol
      css={[typographyStyle(theme, props), listStyle(theme, props)]}
      {...filtered}
      ref={ref}
    />
  );
});

OL.defaultProps = {
  marker: "decimal",
  gutterH: 1.5,
};

export default OL;
