import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type RadioProps = Omit<ToggleProps, "type" | "control">;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(function Radio(
  props,
  ref
) {
  const { ...rest } = props;
  return (
    <Toggle {...rest} control={ToggleControl.Radio} type="radio" ref={ref} />
  );
});

Radio.defaultProps = {
  variant: ToggleVariant.Compact,
  colorScheme: ColorScheme.OnLight,
  controlPosition: ToggleControlPosition.Start,
};

export default Radio;
