import { CSSRulesFunction, Overflow, useTheme } from "../theme";

import { typographyStyle, TypographyStyleProps } from "./appearance";

type BaseProps = Omit<
  TypographyStyleProps,
  "layout" | "textAlign" | "decoration" | "fontFamily"
>;
type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof BaseProps | "children"
>;

export interface CodeBlockProps extends BaseProps, HTMLAttributes {
  /**
   * Controls the overflow behavior when content inside this component is too
   * long
   */
  overflow?: Overflow;

  children: React.ReactNode;
}

const containerStyle: CSSRulesFunction<CodeBlockProps> = (theme, props) => {
  return [
    {
      margin: 0,
      maxWidth: "100%",
      overflow: props.overflow,
    },
    theme.responsive(props.spaceAbove, (v) => ({
      marginTop: theme.spacing(v),
    })),
    theme.responsive(props.spaceBelow, (v) => ({
      marginBottom: theme.spacing(v),
    })),
  ];
};

const codeStyle: CSSRulesFunction<CodeBlockProps> = (theme, props) => {
  return typographyStyle(theme, { ...props, fontFamily: "monospace" });
};

const CodeBlock: React.FC<CodeBlockProps> = (props) => {
  const { theme } = useTheme();
  const { spaceAbove, spaceBelow, ...rest } = props;
  return (
    <pre css={containerStyle(theme, props)}>
      <code css={codeStyle(theme, rest)}>{props.children}</code>
    </pre>
  );
};

CodeBlock.defaultProps = {
  overflow: Overflow.Auto,
};

export default CodeBlock;
