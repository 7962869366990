import { ClassNames } from "@emotion/react";
import * as React from "react";

import { useTheme } from "../theme";
import { omitTypographyStyleProps } from "../typography/appearance";

import { plainButtonStyle } from "./plain";
import type { PlainButtonStyleProps, ButtonRenderProps } from "./types";

type HTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof PlainButtonStyleProps
>;

export interface PlainButtonProps
  extends HTMLAttributes,
    PlainButtonStyleProps {
  children: React.ReactNode;

  /**
   * Customizes the rendering of the underlying button element. This is useful
   * when integrating this component with React frameworks that come with their
   * own router and link component implementations.
   */
  render?: (props: ButtonRenderProps) => React.ReactNode;
}

const PlainButton = React.forwardRef<HTMLButtonElement, PlainButtonProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const { effect, children, render, ...rest } = props;

    return (
      <ClassNames>
        {({ css, cx }) => {
          const className = cx(
            css(plainButtonStyle(theme, props)),
            props.className
          );
          const forwardedProps = {
            ...omitTypographyStyleProps(rest),
            className,
            children,
          };

          return render ? (
            render(forwardedProps)
          ) : (
            <button {...forwardedProps} ref={ref} />
          );
        }}
      </ClassNames>
    );
  }
);

PlainButton.defaultProps = {
  type: "button",
};

export default PlainButton;
