import type * as React from "react";

import {
  CSSRulesFunction,
  useTheme,
  ResponsiveValue,
  ColorPreset,
} from "../theme";

type HTMLAttributes = React.TableHTMLAttributes<HTMLTableElement>;

export interface TableProps extends HTMLAttributes {
  layout?: ResponsiveValue<"contentWidth" | "fullWidth">;
  children?: React.ReactNode;
}

export const tableContainerStyle: CSSRulesFunction<TableProps> = (
  theme,
  props
) => [
  {
    "--table-border-color": theme.color(ColorPreset.BackgroundLight_04),
    border: "1px solid var(--table-border-color)",
    borderRadius: theme.radius(1),
    maxWidth: "100%",
    margin: "0 auto",
    overflow: "auto",
    borderSpacing: 0,
  },
  theme.responsive(props.layout, (l) => ({
    width: l === "fullWidth" ? "100%" : "auto",
  })),
];

const TableContainer: React.FC<TableProps> = (props) => {
  const { theme } = useTheme();
  const { layout, ...rest } = props;
  return <table css={tableContainerStyle(theme, props)} {...rest} />;
};

TableContainer.defaultProps = {
  layout: "contentWidth",
};

export default TableContainer;
