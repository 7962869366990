import type * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";

import { vars } from "./progressBar.styles";
import type { ProgressBarStyleProps } from "./types";

const solidProgressBarStyle: CSSRulesFunction<ProgressBarStyleProps> = (
  theme,
  props
) => {
  const trackV = `var(${vars.trackV})`;
  const fillV = `var(${vars.fillV})`;

  return [
    {
      width: "100%",
      height: "4px",
      backgroundColor: trackV,
      backgroundImage: `linear-gradient(to right, ${fillV}, ${fillV})`,
      backgroundRepeat: "no-repeat",
      transition: "background 200ms",
      "@media (prefers-reduced-motion: reduce)": {
        transitionDuration: "50ms",
      },
    },
    theme.responsive(props.borderRadius, (r) => ({
      borderRadius: theme.radius(r || 0),
    })),
  ];
};

const SolidProgressBar: React.FC<ProgressBarStyleProps> = (props) => {
  const { theme } = useTheme();
  const { value, max } = props;
  const bsize = max === 0 ? "0" : Math.min(100, (100 * value) / max).toFixed(2);
  const backgroundSize = `${bsize}% auto`;

  return (
    <div
      css={solidProgressBarStyle(theme, props)}
      style={{
        backgroundSize,
      }}
    />
  );
};

SolidProgressBar.defaultProps = {
  borderRadius: 0,
};

export default SolidProgressBar;
