import type * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";

import { vars } from "./progressBar.styles";
import type { ProgressBarStyleProps } from "./types";

const segmentedProgressBarStyle: CSSRulesFunction = (theme) => {
  return [
    {
      width: "100%",
      height: "4px",
      display: "grid",
      gridAutoColumns: "1fr",
      columnGap: theme.spacing(0.25),
      gridAutoFlow: "column",
      overflow: "hidden",
    },
  ];
};

const segmentStyle: CSSRulesFunction<ProgressBarStyleProps["borderRadius"]> = (
  theme,
  radius
) => {
  return [
    {
      height: "100%",

      backgroundColor: `var(${vars.trackV})`,
      transition: "background 200ms",
      "@media (prefers-reduced-motion: reduce)": {
        transitionDuration: "50ms",
      },
    },
    theme.responsive(radius, (r) => ({
      borderRadius: theme.radius(r || 0),
    })),
  ];
};

const SegmentedProgressBar: React.FC<ProgressBarStyleProps> = (props) => {
  const { theme } = useTheme();
  const { value, max, borderRadius } = props;
  const segmentCSS = segmentStyle(theme, borderRadius);

  return (
    <div css={segmentedProgressBarStyle(theme)}>
      {Array.from({ length: max }).map((_, i) => {
        let style: React.CSSProperties | undefined = undefined;
        if (value > i) {
          style = { backgroundColor: `var(${vars.fillV})` };
        }
        return <div key={i} css={segmentCSS} style={style} />;
      })}
    </div>
  );
};

SegmentedProgressBar.defaultProps = {
  borderRadius: 0.5,
};

export default SegmentedProgressBar;
