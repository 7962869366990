import * as React from "react";

import { Glyph, Icon } from "../icons";
import { ColorScheme, useTheme } from "../theme";
import { omitTypographyStyleProps } from "../typography/appearance";

import {
  breadcrumbsItemStyle,
  breadcrumbsLinkStyle,
  breadcrumbsSeparatorStyle,
  breadcrumbsStyle,
} from "./breadcrumbsStyles";
import type {
  BreadcrumbsItemStyleProps,
  BreadcrumbsStyleProps,
} from "./breadcrumbsTypes";

const BreadcrumbsSeparatorContext = React.createContext<React.ReactNode>(null);
const BreadcrumbsSeparator: React.FC = () => {
  const node = React.useContext(BreadcrumbsSeparatorContext);
  return React.isValidElement(node) ? node : null;
};

export interface BreadcrumbsItemProps
  extends Partial<BreadcrumbsItemStyleProps> {
  href: string;
  children: React.ReactNode;

  /**
   * @ignore This is used internally to stagger items in column layout
   */
  level?: number;
}

export const BreadcrumbsItem: React.FC<BreadcrumbsItemProps> = (props) => {
  const { theme } = useTheme();
  const { level = 0, ...rest } = props;
  return (
    <li
      css={breadcrumbsItemStyle(theme, { level, ...rest })}
      {...omitTypographyStyleProps(rest)}
    >
      <BreadcrumbsSeparator />
      <a
        css={[breadcrumbsLinkStyle, { borderRadius: theme.radius(0.25) }]}
        href={props.href}
      >
        {props.children}
      </a>
    </li>
  );
};

type HTMLAttributes = Omit<
  React.OlHTMLAttributes<HTMLOListElement>,
  keyof BreadcrumbsStyleProps
>;

export interface BreadcrumbsProps
  extends HTMLAttributes,
    Partial<BreadcrumbsStyleProps> {
  /**
   * The localized label to use for the breadcrumb navigation element. This is
   * typically the word "Breadcrumbs" or an appropriate translation of it.
   */
  "aria-label"?: string;
  /**
   * The type of separator to display between entries in this component.
   */
  separator?: Glyph.ArrowForwardLong | "/";
  children: React.ReactNode;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const {
    separator = Glyph.ArrowForwardLong,
    colorScheme = ColorScheme.OnLight,
    layout = ["column", null, "row"],
    children,
    color,
    ...rest
  } = props;
  const { theme } = useTheme();
  const sepNode = React.useMemo(
    () =>
      separator === "/" ? (
        <span css={breadcrumbsSeparatorStyle} aria-hidden>
          /
        </span>
      ) : (
        <Icon css={breadcrumbsSeparatorStyle} name={separator} />
      ),
    [separator, breadcrumbsSeparatorStyle]
  );

  let level = 0;
  const nodes = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    const childProps = { level, separator: level > 0 };
    const clone = React.cloneElement(child, childProps);
    level += 1;
    return clone;
  });

  return (
    <ol
      css={breadcrumbsStyle(theme, { colorScheme, layout, ...rest })}
      {...omitTypographyStyleProps(rest)}
    >
      <BreadcrumbsSeparatorContext.Provider value={sepNode}>
        {nodes}
      </BreadcrumbsSeparatorContext.Provider>
    </ol>
  );
};

export default Breadcrumbs;
