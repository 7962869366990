import * as React from "react";

import { FontWeight, useTheme } from "../theme";
import { omitTypographyStyleProps } from "../typography/appearance";

import { ddStyle, dlAccessoryStyle } from "./dlStyle";
import type { DDStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof DDStyleProps
>;

export interface DDProps extends HTMLAttributes, DDStyleProps {
  children: React.ReactNode;
}

const DD: React.FC<DDProps> = (props) => {
  const { theme } = useTheme();
  const { rightAccessory, children, ...rest } = omitTypographyStyleProps(props);

  return (
    <dd css={ddStyle(theme, props)} {...rest}>
      <span>{children}</span>
      {rightAccessory && React.Children.count(rightAccessory) > 0 ? (
        <span css={dlAccessoryStyle(theme, "right")}>{rightAccessory}</span>
      ) : null}
    </dd>
  );
};
DD.defaultProps = {
  weight: FontWeight.SemiBold,
};

export default DD;
