import type { CSSInterpolation } from "@emotion/serialize";

export type CSSValue<V> = V | [V, V, V?, V?];

export enum Breakpoint {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export type ResponsiveValue<Value> = Value | (Value | null)[];

export enum Color {
  Dawn_1400 = "dawn-1400",
  Dawn_1300 = "dawn-1300",
  Dawn_1200 = "dawn-1200",
  Dawn_1100 = "dawn-1100",
  Dawn_1000 = "dawn-1000",
  Dawn_900 = "dawn-900",
  Dawn_800 = "dawn-800",
  Dawn_700 = "dawn-700",
  Dawn_600 = "dawn-600",
  Dawn_500 = "dawn-500",
  Dawn_400 = "dawn-400",
  Dawn_300 = "dawn-300",
  Dawn_200 = "dawn-200",
  Dawn_100 = "dawn-100",
  Dawn_50 = "dawn-50",
  Dawn_Base = "dawn-base",

  Brownstone_1400 = "brownstone-1400",
  Brownstone_1400_A08 = "brownstone-1400-a08",
  Brownstone_1300 = "brownstone-1300",
  Brownstone_1200 = "brownstone-1200",
  Brownstone_1100 = "brownstone-1100",
  Brownstone_1000 = "brownstone-1000",
  Brownstone_900 = "brownstone-900",
  Brownstone_800 = "brownstone-800",
  Brownstone_700 = "brownstone-700",
  Brownstone_600 = "brownstone-600",
  Brownstone_500 = "brownstone-500",
  Brownstone_400 = "brownstone-400",
  Brownstone_300 = "brownstone-300",
  Brownstone_200 = "brownstone-200",
  Brownstone_100 = "brownstone-100",
  Brownstone_50 = "brownstone-50",
  Brownstone_700_A12 = "brownstone-700-a12",
  Brownstone_700_A21 = "brownstone-700-a21",
  Brownstone_Base = "brownstone-base",
  Brownstone_Moonstone = "brownstone-moonstone",

  Greystone_1400 = "greystone-1400",
  Greystone_1300 = "greystone-1300",
  Greystone_1200 = "greystone-1200",
  Greystone_1100 = "greystone-1100",
  Greystone_1000 = "greystone-1000",
  Greystone_900_A21 = "greystone-900-a21",
  Greystone_900 = "greystone-900",
  Greystone_800 = "greystone-800",
  Greystone_700 = "greystone-700",
  Greystone_600 = "greystone-600",
  Greystone_500 = "greystone-500",
  Greystone_400 = "greystone-400",
  Greystone_300 = "greystone-300",
  Greystone_200 = "greystone-200",
  Greystone_100 = "greystone-100",
  Greystone_50 = "greystone-50",
  Greystone_1400_A90 = "greystone-1400-a90",
  Greystone_1400_A50 = "greystone-1400-a50",
  Greystone_1400_A38 = "greystone-1400-a38",
  Greystone_1400_A16 = "greystone-1400-a16",
  Greystone_1400_A8 = "greystone-1400-a8",
  Greystone_700_A38 = "greystone-700-a38",
  Greystone_700_A16 = "greystone-700-a16",
  Greystone_Base = "greystone-base",
  Greystone_DarkMatter = "greystone-darkmatter",

  Sunset_1400 = "sunset-1400",
  Sunset_1300 = "sunset-1300",
  Sunset_1200 = "sunset-1200",
  Sunset_1100 = "sunset-1100",
  Sunset_1000 = "sunset-1000",
  Sunset_900 = "sunset-900",
  Sunset_800 = "sunset-800",
  Sunset_700 = "sunset-700",
  Sunset_600 = "sunset-600",
  Sunset_500 = "sunset-500",
  Sunset_400 = "sunset-400",
  Sunset_300 = "sunset-300",
  Sunset_200 = "sunset-200",
  Sunset_100 = "sunset-100",
  Sunset_50 = "sunset-50",
  Sunset_Base = "sunset-base",

  Sunrise_1400 = "sunrise-1400",
  Sunrise_1300 = "sunrise-1300",
  Sunrise_1200 = "sunrise-1200",
  Sunrise_1100 = "sunrise-1100",
  Sunrise_1000 = "sunrise-1000",
  Sunrise_900 = "sunrise-900",
  Sunrise_800 = "sunrise-800",
  Sunrise_700 = "sunrise-700",
  Sunrise_600 = "sunrise-600",
  Sunrise_500 = "sunrise-500",
  Sunrise_400 = "sunrise-400",
  Sunrise_300 = "sunrise-300",
  Sunrise_200 = "sunrise-200",
  Sunrise_100 = "sunrise-100",
  Sunrise_50 = "sunrise-50",
  Sunrise_Base = "sunrise-base",

  Slate_1400 = "slate-1400",
  Slate_1300 = "slate-1300",
  Slate_1200 = "slate-1200",
  Slate_1100 = "slate-1100",
  Slate_1000 = "slate-1000",
  Slate_900 = "slate-900",
  Slate_800 = "slate-800",
  Slate_700 = "slate-700",
  Slate_600 = "slate-600",
  Slate_500 = "slate-500",
  Slate_400 = "slate-400",
  Slate_300 = "slate-300",
  Slate_200 = "slate-200",
  Slate_100 = "slate-100",
  Slate_50 = "slate-50",
  Slate_Base = "slate-base",

  Pebble_1400 = "pebble-1400",
  Pebble_1300 = "pebble-1300",
  Pebble_1200 = "pebble-1200",
  Pebble_1100 = "pebble-1100",
  Pebble_1000 = "pebble-1000",
  Pebble_900 = "pebble-900",
  Pebble_800 = "pebble-800",
  Pebble_700 = "pebble-700",
  Pebble_600 = "pebble-600",
  Pebble_500 = "pebble-500",
  Pebble_400 = "pebble-400",
  Pebble_300 = "pebble-300",
  Pebble_200 = "pebble-200",
  Pebble_100 = "pebble-100",
  Pebble_50 = "pebble-50",
  Pebble_Base = "pebble-base",

  Ocean_1400 = "ocean-1400",
  Ocean_1300 = "ocean-1300",
  Ocean_1200 = "ocean-1200",
  Ocean_1100 = "ocean-1100",
  Ocean_1000 = "ocean-1000",
  Ocean_900 = "ocean-900",
  Ocean_800 = "ocean-800",
  Ocean_700 = "ocean-700",
  Ocean_600 = "ocean-600",
  Ocean_500 = "ocean-500",
  Ocean_400 = "ocean-400",
  Ocean_300 = "ocean-300",
  Ocean_200 = "ocean-200",
  Ocean_100 = "ocean-100",
  Ocean_50 = "ocean-50",
  Ocean_Base = "ocean-base",

  Ultraviolet_1400 = "ultraviolet-1400",
  Ultraviolet_1300 = "ultraviolet-1300",
  Ultraviolet_1200 = "ultraviolet-1200",
  Ultraviolet_1100 = "ultraviolet-1100",
  Ultraviolet_1000 = "ultraviolet-1000",
  Ultraviolet_900 = "ultraviolet-900",
  Ultraviolet_800 = "ultraviolet-800",
  Ultraviolet_700 = "ultraviolet-700",
  Ultraviolet_600 = "ultraviolet-600",
  Ultraviolet_500 = "ultraviolet-500",
  Ultraviolet_400 = "ultraviolet-400",
  Ultraviolet_300 = "ultraviolet-300",
  Ultraviolet_200 = "ultraviolet-200",
  Ultraviolet_100 = "ultraviolet-100",
  Ultraviolet_50 = "ultraviolet-50",
  Ultraviolet_Base = "ultraviolet-base",

  Dusk_1400 = "dusk-1400",
  Dusk_1300 = "dusk-1300",
  Dusk_1200 = "dusk-1200",
  Dusk_1100 = "dusk-1100",
  Dusk_1000 = "dusk-1000",
  Dusk_900 = "dusk-900",
  Dusk_800 = "dusk-800",
  Dusk_700 = "dusk-700",
  Dusk_600 = "dusk-600",
  Dusk_500 = "dusk-500",
  Dusk_400 = "dusk-400",
  Dusk_300 = "dusk-300",
  Dusk_200 = "dusk-200",
  Dusk_100 = "dusk-100",
  Dusk_50 = "dusk-50",
  Dusk_Base = "dusk-base",

  Clay_1400 = "clay-1400",
  Clay_1300 = "clay-1300",
  Clay_1200 = "clay-1200",
  Clay_1100 = "clay-1100",
  Clay_1000 = "clay-1000",
  Clay_900 = "clay-900",
  Clay_800 = "clay-800",
  Clay_700 = "clay-700",
  Clay_600 = "clay-600",
  Clay_500 = "clay-500",
  Clay_400 = "clay-400",
  Clay_300 = "clay-300",
  Clay_200 = "clay-200",
  Clay_100 = "clay-100",
  Clay_50 = "clay-50",
  Clay_Base = "clay-base",

  Evergreen_1400 = "evergreen-1400",
  Evergreen_1300 = "evergreen-1300",
  Evergreen_1200 = "evergreen-1200",
  Evergreen_1100 = "evergreen-1100",
  Evergreen_1000 = "evergreen-1000",
  Evergreen_900 = "evergreen-900",
  Evergreen_800 = "evergreen-800",
  Evergreen_700 = "evergreen-700",
  Evergreen_600 = "evergreen-600",
  Evergreen_500 = "evergreen-500",
  Evergreen_400 = "evergreen-400",
  Evergreen_300 = "evergreen-300",
  Evergreen_200 = "evergreen-200",
  Evergreen_100 = "evergreen-100",
  Evergreen_50 = "evergreen-50",
  Evergreen_Base = "evergreen-base",

  Alert_500 = "alert-500",
  Alert_400 = "alert-400",
  Alert_300 = "alert-300",
  Alert_200 = "alert-200",
  Alert_100 = "alert-100",
  Alert_50 = "alert-50",
  Alert_Base = "alert-base",
  Alert_50_A25 = "alert-50-a25",

  Warning_500 = "warning-500",
  Warning_400 = "warning-400",
  Warning_300 = "warning-300",
  Warning_200 = "warning-200",
  Warning_100 = "warning-100",
  Warning_50 = "warning-50",
  Warning_Base = "warning-base",
  Warning_50_A25 = "warning-50-a25",

  Success_500 = "success-500",
  Success_400 = "success-400",
  Success_300 = "success-300",
  Success_200 = "success-200",
  Success_100 = "success-100",
  Success_50 = "success-50",
  Success_Base = "success-base",
  Success_50_A25 = "success-50-a25",

  Info_500 = "info-500",
  Info_400 = "info-400",
  Info_300 = "info-300",
  Info_200 = "info-200",
  Info_100 = "info-100",
  Info_50 = "info-50",
  Info_Base = "info-base",
  Info_50_A25 = "info-50-a25",

  White = "white",
  White_A25 = "white-a25",
  White_A12 = "white-a12",
  Transparent = "transparent",
}

export enum ColorPreset {
  BackgroundLight_00 = "background-light-00",
  BackgroundLight_01 = "background-light-01",
  BackgroundLight_02 = "background-light-02",
  BackgroundLight_03 = "background-light-03",
  BackgroundLight_04 = "background-light-04",
  BackgroundDark_01 = "background-dark-01",
  BackgroundDark_02 = "background-dark-02",
  BackgroundDark_03 = "background-dark-03",
  BackgroundDark_04 = "background-dark-04",

  TextOnLight_01 = "text-on-light-01",
  TextOnLight_02 = "text-on-light-02",
  TextOnLight_03 = "text-on-light-03",
  TextOnDark_00 = "text-on-dark-00",
  TextOnDark_01 = "text-on-dark-01",
  TextOnDark_02 = "text-on-dark-02",
  TextOnDark_03 = "text-on-dark-03",

  IconOnLight_01 = "icon-on-light-01",
  IconOnLight_02 = "icon-on-light-02",
  IconOnLight_03 = "icon-on-light-03",
  IconOnLight_04 = "icon-on-light-04",
  IconOnDark_00 = "icon-on-dark-00",
  IconOnDark_01 = "icon-on-dark-01",
  IconOnDark_02 = "icon-on-dark-02",
  IconOnDark_03 = "icon-on-dark-03",
  IconOnDark_04 = "icon-on-dark-04",

  BorderOnLight_01 = "border-on-light-01",
  BorderOnLight_02 = "border-on-light-02",
  BorderOnLight_03 = "border-on-light-03",
  BorderOnLight_04 = "border-on-light-04",
  BorderOnLight_05 = "border-on-light-05",
  BorderOnDark_01 = "border-on-dark-01",
  BorderOnDark_02 = "border-on-dark-02",
  BorderOnDark_03 = "border-on-dark-03",
  BorderOnDark_04 = "border-on-dark-04",
  BorderOnDark_05 = "border-on-dark-05",

  AlertTextOnLight = "alert-text-on-light",
  AlertIconOnLight = "alert-icon-on-light",
  AlertBorderOnLight = "alert-border-on-light",
  AlertTextOnDark = "alert-text-on-dark",
  AlertIconOnDark = "alert-icon-on-dark",
  AlertBorderOnDark = "alert-border-on-dark",

  DataOnLight_01 = "data-on-light-01",
  DataOnLight_02 = "data-on-light-02",
  DataOnLight_03 = "data-on-light-03",
  DataOnLight_04 = "data-on-light-04",
  DataOnLight_05 = "data-on-light-05",
  DataOnLight_06 = "data-on-light-06",
  DataOnLight_07 = "data-on-light-07",
  DataOnLight_08 = "data-on-light-08",
  DataOnDark_01 = "data-on-dark-01",
  DataOnDark_02 = "data-on-dark-02",
  DataOnDark_03 = "data-on-dark-03",
  DataOnDark_04 = "data-on-dark-04",
  DataOnDark_05 = "data-on-dark-05",
  DataOnDark_06 = "data-on-dark-06",
  DataOnDark_07 = "data-on-dark-07",
  DataOnDark_08 = "data-on-dark-08",
}

export type ColorProp = Color | ColorPreset;

export interface TypographyStyleProps {
  /**
   * Sets a combination of typography rules defined by a preset.
   */
  preset?: TypePreset;

  /**
   * Changes the font family used for text content
   */
  fontFamily?: FontFamily;

  /**
   * Controls the layout of this component (see [`display` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/display))
   */
  layout?: ResponsiveValue<"block" | "inline-block" | "inline">;

  /**
   * Add spacing above the component
   */
  spaceAbove?: ResponsiveValue<SpaceScale>;

  /**
   * Add spacing below the component
   */
  spaceBelow?: ResponsiveValue<SpaceScale>;

  /**
   * Changes the text color for text content inside this component
   */
  color?: ResponsiveValue<ColorProp>;

  /**
   * Sets the components font size **and** line height based on the typographic
   * scale defined in the theme.
   */
  size?: ResponsiveValue<TypeScale>;

  /**
   * Overrides the default line height defined by the component's `size` prop
   * This prop is useful when typesetting text in dense areas like card titles
   * and description and needing a tighter line height.
   */
  lineHeight?: ResponsiveValue<TypeScale>;

  /**
   * Changes the font weight of text content inside this component
   */
  weight?: ResponsiveValue<FontWeight>;

  /**
   * Changes the text alignment
   */
  textAlign?: ResponsiveValue<TextAlign>;

  /**
   * Changes text wrapping behavior
   */
  textWrap?: ResponsiveValue<TextWrap>;

  /**
   * Toggle between normal or italicized text
   */
  fontStyle?: ResponsiveValue<"normal" | "italic">;

  /**
   * Applies different decorations to text such as underlining it
   */
  decoration?: ResponsiveValue<"underline" | "line-through" | "none">;

  /**
   * Change the displayed casing of text inside this component. This does not
   * alter the actual content of the component, only how it is displayed.
   */
  textTransform?: ResponsiveValue<"uppercase" | "capitalize" | "none">;

  /**
   * Controls whether the HTML element is hidden or show (by CSS) if it does not
   * have any content. This is useful when rendering rich text that can contain
   * empty paragraphs and it is not desirable to have them take up vertical
   * space.
   */
  emptyVisibility?: "visible" | "hidden";
}

export enum ButtonSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export enum TooltipTheme {
  Light = "light",
  Dark = "dark",
}

export type Elevation = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum FontWeight {
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export enum FontStyle {
  Normal = "normal",
  Italic = "italic",
}

export enum TypeScale {
  Size_01 = 1,
  Size_02 = 2,
  Size_03 = 3,
  Size_04 = 4,
  Size_05 = 5,
  Size_06 = 6,
  Size_07 = 7,
  Size_08 = 8,
  Size_09 = 9,
  Size_10 = 10,
  Size_11 = 11,
  Size_12 = 12,
  Size_13 = 13,
  Size_14 = 14,
  Size_15 = 15,
  Size_16 = 16,
  Size_17 = 17,
  Size_18 = 18,
  Size_19 = 19,
  Size_20 = 20,
  Size_21 = 21,
  Size_22 = 22,
}

export enum TypePreset {
  Body_01 = "body-01",
  Body_02 = "body-02",
  Body_03 = "body-03",
  Body_04 = "body-04",

  LongForm_01 = "longform-01",
  LongForm_02 = "longform-02",
  LongForm_03 = "longform-03",
  LongForm_04 = "longform-04",

  Caption_01 = "caption-01",

  Heading_01 = "heading-01",
  Heading_02 = "heading-02",
  Heading_03 = "heading-03",
  Heading_04 = "heading-04",
  Heading_05 = "heading-05",
  Heading_06 = "heading-06",
  Heading_07 = "heading-07",
  Heading_08 = "heading-08",
  Heading_09 = "heading-09",

  Code_01 = "code-01",
  Code_02 = "code-02",
  Code_03 = "code-03",
  Code_04 = "code-04",

  Subheading_01 = "subheading-01",
  Subheading_02 = "subheading-02",

  Quote_01 = "quote-01",
  Quote_02 = "quote-02",

  Display_01 = "display-01",
  Display_02 = "display-02",
}

export type SpaceScale =
  | 0
  | 0.25
  | 0.5
  | 0.75
  | 1
  | 1.5
  | 2
  | 3
  | 4
  | 6
  | 8
  | 12
  | 16
  | 18
  | 24
  | 42
  | 40
  | 48;

export type BorderRadiusScale = 0 | 0.25 | 0.5 | 1 | 1.5 | 2 | 3;

export type BorderSize = 0 | 1 | 2;

export type ColumnSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum AlignItems {
  Start = "start",
  End = "end",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Center = "center",
  Stretch = "stretch",
  Baseline = "baseline",
}

export enum AlignContent {
  Start = "start",
  End = "end",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Center = "center",
  SpaceAround = "space-around",
  SpaceBetween = "space-between",
}

export enum JustifyItems {
  Auto = "auto",
  Normal = "normal",
  Stretch = "stretch",
  Start = "start",
  End = "end",
  Baseline = "baseline",
  Center = "center",
}

export enum JustifyContent {
  Start = "start",
  End = "end",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Center = "center",
  SpaceAround = "space-around",
  SpaceBetween = "space-between",
}

export type FlexWrap = "wrap" | "nowrap" | "wrap-reverse";

export enum TextAlign {
  Left = "left",
  Right = "right",
  Center = "center",
  Justify = "justify",
}

export enum TextWrap {
  Normal = "normal",
  NoWrap = "nowrap",
}

export enum Overflow {
  Visible = "visible",
  Hidden = "hidden",
  Auto = "auto",
  Scroll = "scroll",
}

export enum ZIndex {
  SecondaryNavigation = 100,
  Sidebar = 200,
  Dropdown = 300,
  Dialog = 400,
  Tooltip = 450,
  Toast = 500,

  // keep this as the highest z-index
  SkipLink = 600,
}

export type FontFamily = "sans-serif" | "serif" | "display" | "monospace";

export enum HoverEffect {
  Lift = "lift",
  TextDecoration = "text-decoration",
}

export enum ColorScheme {
  OnLight = "on-light",
  OnDark = "on-dark",
  Auto = "auto",
}

export enum InputGutter {
  Sm = "sm",
  Md = "md",
}

export enum FocusRing {
  Heavy = "heavy",
  Inner = "inner",
}

export enum AccessorySize {
  Sm = "Sm",
  Md = "Md",
  Lg = "Lg",
}

/**
 * Interpolation is a type for data that contains CSS values. These include:
 * - Plain CSS values such as strings and numbers
 * - Strings containing CSS rules
 * - An object containing CSS rules
 * - An array of CSS rules
 * - A function that takes a theme object and returns any of the above
 *
 * @example Valid values for interpolations
 *
 * 300
 * "inline-block"
 * "display: flex; flex-wrap: nowrap;"
 * { display: "flex", flexWrap: "nowrap" }
 * ["display: flex", { flexWrap: "nowrap" }]
 * theme => ({ padding: theme.spacing(0.5) })
 */
export type Interpolation = CSSInterpolation;

/**
 * A convenience type for functions that need both a theme and some component
 * props and return an interpolation of CSS rules
 */
export type CSSRulesFunction<Props = void> = (
  theme: Theme,
  props: Props
) => CSSInterpolation;

export interface DesignTokens {
  /**
   * The font families to use for various typography elements
   */
  fontFamilies: Record<FontFamily, string>;

  /** The responsive breakpoints to use for all responsive rules */
  breakpoints: Record<Breakpoint, string>;

  /** The color palette grouped by color names and their tint values */
  colors: Record<ColorProp, string>;

  /** Useful color presets */
  colorPresets: Record<ColorPreset, string>;

  /** The typographic scale which defines sizes and line heights */
  fontSizes: Record<TypeScale, { fontSize: string; lineHeight: string }>;

  /** Preconfigured typography presets */
  typePresets: Record<TypePreset, TypographyStyleProps>;

  /** Elevations corresponding to various levels of drop-shadows  */
  elevation: Record<Elevation, string>;

  /** The base spacing value which is used to derive the spacing scale */
  spacingBase: number;

  /** The base radius value which is used to derive the border radius scale */
  borderRadiusBase: number;

  /** The maximum width of the content area set by page-level containers */
  contentMaxWidth: ResponsiveValue<string>;

  /** The page-level gutters (padding) to use after each breakpoint */
  contentGutters: SpaceScale;

  // In order to prevent overflow from the grid's negative outer margins, it's a
  // good idea to define these to be less than or equal to their corresponding
  // contentGutters.
  /**
   * The grid's internal gutters after each breakpoint.
   */
  gridGutters: ResponsiveValue<SpaceScale>;
}

export interface Theme {
  readonly tokens: DesignTokens;

  spacing(scale: CSSValue<SpaceScale>): string;

  radius(scale: CSSValue<BorderRadiusScale>): string;

  color(name: ColorProp): string;

  responsive<Value>(
    values: ResponsiveValue<Value> | null | undefined,
    interpolate: (value: Value, theme: Theme) => CSSInterpolation
  ): CSSInterpolation;
}
